<template>
  <b-sidebar
      id="add-new-speciality-sidebar"
      :visible="isAddNewSpecialitySidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-speciality-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Speciality
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <b-card-code>
            <!-- Title En -->
            <validation-provider
                #default="validationContext"
                name="Title En"
                rules="required"
            >
              <b-form-group
                  label="Title En"
                  label-for="title_en"
              >
                <b-form-input
                    id="title_en"
                    v-model="specialityData.title_en"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Speciality Title En"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Title Ar -->
            <b-form-group
                label="Title Ar"
                label-for="title_ar"
            >
              <b-form-input
                  id="title_ar"
                  v-model="specialityData.title_ar"
                  autofocus
                  trim
                  placeholder="Speciality Title Ar"
              />
            </b-form-group>

            <!-- Flag -->
            <file
                title="Select Speciality Image"
                :default_place_holder_src="specialityData.image"
                @file_uploaded="({media})=>{specialityData.image= media.url; specialityId = media.id}"
            ></file>

           </b-card-code>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              Add
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BFormCheckbox,BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {required, alphaNum, email} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,
    BCardCode,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSpecialitySidebarActive',
    event: 'update:is-add-new-speciality-sidebar-active',
  },
  props: {
    isAddNewSpecialitySidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      requireImage:false
    }
  },
  setup(props, {emit}) {
    const blankSpecialityData = {
      title_en: '',
      title_ar: '',
      is_active: true,
      image: null,
    }
    const specialityId =ref(null);
    const specialityData = ref(JSON.parse(JSON.stringify(blankSpecialityData)))
    const resetSpecialityData = () => {
      specialityData.value = JSON.parse(JSON.stringify(blankSpecialityData))
    }

    const onSubmit = () => {
      let data = {
        title_en: specialityData.value.title_en,
        title_ar: specialityData.value.title_ar,
        is_active: specialityData.value.is_active,
        image : specialityId.value
      }
      // if (specialityData.flag != null){
        store.dispatch('app-speciality/addSpeciality', data)
            .then(() => {
              emit('refetch-data')
              emit('update:is-add-new-speciality-sidebar-active', false)
            })
      // }

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSpecialityData)

    return {
      specialityData,
      onSubmit,
      specialityId,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
